h3.title {
  font-weight: bold;
  font-size: 28px;
}

a {
  text-decoration: none;
}

div.section {
  margin-bottom: 24px;
}

div.subsection {
  margin-bottom: 12px;
}

span.subsection-title {
  font-size: 20px;
}

span.subsection-description {
  color: DimGray;
}
