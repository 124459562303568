.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.contact {
  color: black;
}

a {
  text-decoration: none;
}

div.container {
  padding: 16px;
}
