div.tag {
  border: 1px solid black;
  border-radius: 5px;
  width: fit-content;
  padding: 3px;
  margin-top: 4px;
  margin-right: 8px;
  display: inline-block;
}

div.tag:hover,
div.filtered {
  background-color: #dfdfdf;
}

div.filtered {
  border-color: SlateBlue !important;
}

span.filtered {
  color: SlateBlue !important;
}

span.icon {
  margin-right: 2px;
}

div.filterable {
  cursor: pointer;
}
